import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMenuItems } from "core/hooks/useMenuItems";
import {
  useDMSFormErrorHandler,
  useDocumentById,
  useInfiniteDocuments,
  useViewableDocuments,
} from "features/DMS/hooks";
import {
  DocDocumentDto,
  DocumentPermissionItemDto,
  NewDocumentFormValues,
  ViewablePermissionsFormValues,
} from "features/DMS/entities";
import { arrayToObject } from "core/utils/arrayToObject";
import { ALL_VALUE } from "appConfiguration";
import { useCentrixFetch } from "core/hooks/useCentrixFetch";
import { useToaster } from "core/hooks/useToaster";
import { dmsDocumentFormValuesToCreateUpdateDto } from "../PermissionManagement/ViewablePermissionsManagement/utils/dmsDocumentFormValuesToCreateUpdateDto";
import { useViewableDMSPermissionOptions } from "../PermissionManagement/ViewablePermissionsManagement";
import { DocumentForm, defaultValues } from "./DocumentForm";

interface DocumentEditProps {
  id: string;
  parentId: string;
  docLibraryId: string;
  cancelOnClick: () => void;
  canManageLibraryContents: boolean;
}

function apiPermissionsToFormPermissions(
  permissions: DocDocumentDto["permissions"]
): ViewablePermissionsFormValues {
  const initial: ViewablePermissionsFormValues = {
    brands: [],
    regions: [],
    departments: [],
    shops: [],
    corporateRoles: [],
    shopRoles: [],
    isVisibleToShops: false,
    toggle: "departments",
  };

  const formPermissions = permissions?.reduce(
    (acc, { brand, departmentName, shopId, region, roleId, shopRoleId }) => {
      if (brand && !acc.brands.includes(brand) && brand !== ALL_VALUE) {
        acc.brands.push(brand);
      }
      if (region && !acc.regions.includes(region) && region !== ALL_VALUE) {
        acc.regions.push(region);
      }
      if (departmentName && !acc.departments.includes(departmentName)) {
        acc.departments.push(departmentName);
      }
      if (shopId && !acc.shops.includes(shopId)) {
        acc.isVisibleToShops = true;
        acc.shops.push(shopId);
      }
      if (roleId && !acc.corporateRoles.includes(roleId)) {
        acc.toggle = "roles";
        acc.corporateRoles.push(roleId);
      }
      if (shopRoleId && !acc.shopRoles.includes(shopRoleId)) {
        acc.shopRoles.push(shopRoleId);
      }
      return acc;
    },
    initial
  );
  return formPermissions || initial;
}

export function DocumentEdit({
  cancelOnClick,
  parentId,
  id,
  docLibraryId,
  canManageLibraryContents,
}: DocumentEditProps) {
  const formMethods = useForm<NewDocumentFormValues>({
    defaultValues,
    mode: "onChange",
  });

  const { isLoading: isPermOptionsLoading } =
    useViewableDMSPermissionOptions(docLibraryId);
  const {
    data: documentData,
    processedDocument,
    isLoading: isDocumentDataLoading,
    isError,
    mutate,
  } = useDocumentById(id);
  const { mutate: mutateManageableDocuments } = useInfiniteDocuments({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const { mutate: mutateViewableDocuments } = useInfiniteDocuments({
    parentId,
    mode: "view",
    canManageLibraryContents,
  });
  const isLoading = isPermOptionsLoading || isDocumentDataLoading;
  const { t } = useTranslation("DocumentManagementSystem");
  // const apiPutJson = useAPIPutJSON();

  const centrixFetch = useCentrixFetch();
  const { errorToast, successToast } = useToaster();
  const { mutate: mutateMenuItems } = useMenuItems();
  const { mutate: mutateDMSWidgetDocuments } = useViewableDocuments();
  const { errorHandler } = useDMSFormErrorHandler(formMethods, "DocumentForm");

  useEffect(() => {
    if (!isLoading && !isError && documentData) {
      const {
        translations: translationsArray,
        attachments: attachmentsArray,
        permissions,
        availableFrom,
        availableTo,
        ...rest
      } = structuredClone(documentData);

      const isDocumentUpload =
        !processedDocument?.currentAttachment?.documentLink;
      const translations =
        (translationsArray &&
          arrayToObject(
            translationsArray.map((obj) => ({ ...obj })),
            "transKey"
          )) ||
        {};
      const attachments =
        (attachmentsArray &&
          arrayToObject(
            attachmentsArray.map((obj) => ({ ...obj })),
            "transKey"
          )) ||
        {};
      const processedPermissions = apiPermissionsToFormPermissions(
        permissions || []
      );
      formMethods.reset({
        translations,
        attachments,
        isDocumentUpload,
        permissions: processedPermissions,
        availableFrom:
          typeof availableFrom === "string" ? new Date(availableFrom) : null,
        availableTo:
          typeof availableTo === "string" ? new Date(availableTo) : null,
        ...rest,
      });
    }
  }, [
    isLoading,
    isError,
    formMethods,
    documentData,
    processedDocument?.currentAttachment?.documentLink,
  ]);
  const successMessage = t(
    "Next:DocumentManagementSystem:DocumentEdit.PUTSuccess"
  );
  const errorMessage = t("Next:DocumentManagementSystem:DocumentEdit.PUTError");

  async function onValid(data: NewDocumentFormValues) {
    const body = dmsDocumentFormValuesToCreateUpdateDto(data, parentId);
    const editDocResponse = await centrixFetch({
      method: "put",
      path: "/api/app/doc-document/{id}",
      parameters: { path: { id } },
      body,
    });
    if (!editDocResponse.ok) {
      errorToast(errorMessage);
      return;
    }
    const updatedDocument = await editDocResponse.json();
    await Promise.all([
      mutate({
        ...updatedDocument,
        permissions: body.permissions as DocumentPermissionItemDto[],
      }),
      mutateManageableDocuments((currentResponses) => {
        if (!Array.isArray(currentResponses)) {
          return [];
        }
        if (!Array.isArray(currentResponses?.[0]?.items)) {
          return [{ items: [updatedDocument], totalCount: 1 }];
        }
        return currentResponses.map(({ items, totalCount }) => {
          const newItems = items.map((doc) =>
            doc.id === updatedDocument.id
              ? { ...updatedDocument, permissions: body.permissions }
              : doc
          );
          return { items: newItems, totalCount };
        });
      }),
      mutateMenuItems(),
      mutateDMSWidgetDocuments(),
      mutateViewableDocuments(),
    ]);
    successToast(successMessage);
    cancelOnClick();
  }

  const onSubmit = formMethods.handleSubmit(onValid, errorHandler);

  return (
    <FormProvider {...formMethods}>
      <DocumentForm
        formMethods={formMethods}
        cancelOnClick={cancelOnClick}
        docLibraryId={docLibraryId}
        onSubmit={onSubmit}
        componentName="DocumentEdit"
        isLoading={isLoading}
      />
    </FormProvider>
  );
}
