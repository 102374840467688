import LanguageIcon from "@mui/icons-material/Language";
import { Button, Menu, MenuItem } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function PublicLanguageSelect() {
  const { push, pathname, query, asPath, locales, locale } = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t: localeT, i18n } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:LanguageCodes",
  });

  // run once on mount to sync with the current locale
  useEffect(() => {
    i18n.changeLanguage(locale!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selectLanguage(nextLocale: string) {
    setAnchorEl(null);
    push({ pathname, query }, asPath, { locale: nextLocale });
    i18n.changeLanguage(nextLocale);
  }

  return (
    <>
      <Button
        startIcon={<LanguageIcon />}
        data-testid="public-language-select-button"
        onClick={(e) => {
          setAnchorEl(e.target as HTMLElement);
        }}
      >
        {localeT(locale!)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {locales
          ?.filter((loc) => loc !== "default")
          .map((loc) => (
            <MenuItem
              key={loc}
              onClick={() => {
                selectLanguage(loc);
              }}
            >
              {localeT(loc)}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
